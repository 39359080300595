<template>
    <div class="reward-tab">
        <div class="alert-info" v-if="onGoingActivityCount">
            <el-alert type="warning" show-icon>
                <div slot="title">
                    在您筛选的时间范围内，有
                    <span class="hight-light">{{ onGoingActivityCount }}</span>
                    个活动尚未结束，无法进行预估奖金，可在
                    <span class="go-link" @click="goReportReward"> 奖励活动 </span>
                    查看详细信息
                </div>
            </el-alert>
        </div>

        <div class="result-chart" v-show="!hideChart">
            <div ref="rewardChart" class="reward-chart" />
        </div>

        <div class="result-table" v-if="tableData">
            <div class="result-table-header clearfix">
                <span class="result-table-title">推广订单</span>
                <div class="result-table-export">
                    <el-button type="danger" @click="exportEmail">导出</el-button>
                </div>
            </div>
            <div class="result-table-content clearfix">
                <el-table :data="tableData.data">
                    <el-table-column prop="activityName" label="活动名称" min-width="2" />
                    <el-table-column
                        prop="bizType"
                        :formatter="formatBizType"
                        label="订单来源"
                        min-width="2"
                    />
                    <el-table-column label="活动时间" min-width="3">
                        <template slot-scope="scope">
                            {{ getActivityDate(scope.row) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="orderCount"
                        label="有效订单量"
                        :formatter="formatPrice"
                        min-width="2"
                    >
                        <template slot="header">
                            <div style="padding-left: 0">
                                <span>有效订单量</span>
                                <reward-tooltip>
                                    <p>查询时间范围内，按活动汇总的满足活动规则要求的待结算和已结算订单量（该数据已去除失效订单）</p>
                                </reward-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="rewardAmount"
                        label="预估活动服务费"
                        :formatter="formatAmount"
                        min-width="2"
                    />
                    <el-table-column min-width="2">
                        <template slot="header">
                            <div style="padding-left: 0;">
                                <span>结算状态 </span>
                                <reward-tooltip>
                                    <p>待结算：订单交易完成，且处于该订单对应的结算期。</p>
                                    <p>
                                        已结算：订单结算完成，每月10日，对上个自然月确认收货订单进行结算。
                                    </p>
                                    <p>
                                        已失效：订单已取消或订单内商品全部发生售后，无需结算的订单。
                                    </p>
                                </reward-tooltip>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            <el-tag :type="getTagType(scope.row)">
                                {{ getSettleStatus(scope.row) }}
                            </el-tag>
                            {{ getSettleTime(scope.row) }}
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" min-width="2">
                        <template slot-scope="scope">
                            <el-button @click="goRewardDetail(scope.row)" type="text" size="small">
                                查看
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="pagination" v-if="tableData.data && tableData.data.length > 0">
                    <pagination
                        :total="tableData.totalCount"
                        :page-sizes="pageSizes"
                        :page-size="pageSize"
                        :current-page="pageIndex"
                        @current-change="handleCurrentChange"
                        @size-change="handleSizeChange"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import pagination from '../common/pagination.vue';
import formatPrice from '../../utils/format-price';
import formatBizType from '../../utils/format-biz-type';
import Tooltip from '../../components/settle/tooltip';
// echarts模块较大 按需引入
const echarts = require('echarts/lib/echarts');
require('echarts/lib/chart/bar');
require('echarts/lib/component/tooltip');

export default {
    components: {
        pagination,
        'reward-tooltip': Tooltip
    },
    props: {
        chartData: Object,
        pageSizes: Array,
        pageSize: Number,
        pageIndex: Number,
        tableData: Object,
        chartWidth: Number,
        chartHeight: Number,
        onGoingActivityCount: Number
    },
    data() {
        return {
            myChart: null,
            hideChart: false
        };
    },
    watch: {
        chartData() {
            const { chartData } = this;
            if (chartData && chartData.activityNames && chartData.activityNames.length) {
                this.renderChart();
            } else {
                this.hideChart = true;
            }
        },
        chartWidth() {
            if (this.chartWidth && this.chartHeight) {
                this.initChart();
            }
        }
    },
    methods: {
        supr(...args) {
            this.$emit('supr', ...args);
        },
        goReportReward() {
            this.$router.push({
                path: '/report/reward'
            });
        },
        initChart() {
            if (this.myChart) {
                return;
            }
            this.myChart = echarts.init(this.$refs.rewardChart, '', {
                width: this.chartWidth,
                height: this.chartHeight
            });
        },
        renderChart() {
            if (!this.myChart) {
                return;
            }
            const { activityNames, rewardAmounts } = this.chartData;
            // 绘制图表
            this.myChart.setOption({
                grid: {
                    left: 60,
                    right: 60,
                    bottom: 95
                },
                tooltip: {
                    trigger: 'item',
                    formatter: (param) => {
                        if (!param || typeof param !== 'object') {
                            return '';
                        }
                        let tip = `${param.name}<br />`;
                        tip += param.marker;
                        tip += `${param.seriesName}：¥${formatPrice(param.value)}`;
                        return tip;
                    }
                },
                xAxis: {
                    data: activityNames,
                    axisLabel: {
                        interval: 0,
                        rotate: -45
                    }

                },
                yAxis: [
                    {
                        position: 'left'
                    }
                ],

                series: [
                    {
                        name: '预估活动服务费',
                        type: 'bar',
                        barWidth: '60%',
                        barMaxWidth: 50,
                        data: rewardAmounts,
                        itemStyle: { color: '#4FABFF' }
                    }
                ]
            });
        },
        exportEmail() {
            this.supr('exportEmail');
        },
        goRewardDetail(row) {
            this.$router.push({
                path: '/report/reward',
                query: {
                    activityId: row.activityId
                }
            });
        },
        handleSizeChange(newSize) {
            this.supr('assign', {
                rewardPageIndex: 1,
                rewardPageSize: newSize
            });
            this.supr('queryRewardTable');
        },
        handleCurrentChange(newIndex) {
            this.supr('assign', {
                rewardPageIndex: newIndex
            });
            this.supr('queryRewardTable');
        },
        getActivityDate(row) {
            const { startTime, endTime } = row;
            return `${moment(parseInt(startTime, 10)).format('YYYY.MM.DD')}-${moment(
                parseInt(endTime, 10)
            ).format('YYYY.MM.DD')}`;
        },
        getSettleStatus(row) {
            const { settleStatus } = row;
            return (
                {
                    0: '待结算',
                    2: '已结算'
                }[parseInt(settleStatus, 10)] || ''
            );
        },
        getSettleTime(row) {
            const { settleStatus, settleTime } = row;
            if (parseInt(settleStatus, 10) === 0) {
                return `（预估${String(settleTime).replace(/-/g, '.')}结算）`;
            }
            return '';
        },
        getTagType(row) {
            const { settleStatus } = row;
            return (
                {
                    0: 'warning',
                    1: 'success',
                    2: 'info'
                }[parseInt(settleStatus, 10)] || ''
            );
        },
        formatAmount(row, column, cellValue) {
            return `¥${formatPrice(cellValue)}`;
        },
        formatPrice(row, column, cellValue) {
            return formatPrice(cellValue);
        },
        formatBizType(row) {
            return formatBizType(row.bizType);
        }
    }
};
</script>

<style lang="scss" scoped>
.reward-tab {
    .alert-info {
        .hight-light {
            color: red;
            font-weight: bold;
        }
        .go-link {
            color: #409eff;
            cursor: pointer;
        }
    }

    .result-chart {
        .reward-chart {
            height: 400px;
        }
    }

    .result-table {
        margin-top: 20px;
        .result-table-header {
            .result-table-title {
                font-size: 18px;
                line-height: 40px;
                font-weight: bold;
            }
            .result-table-export {
                float: right;
            }
        }
        .result-table-content {
            .pagination {
                margin-top: 10px;
                text-align: center;
            }
        }
    }
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: '';
    }
    .clearfix:after {
        clear: both;
    }
}
</style>

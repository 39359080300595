<template>
    <div class="all-tabs" ref="allTabs">
        <el-tabs type="card" @tab-click="tabChange">
            <el-tab-pane>
                <div slot="label">
                    <div class="reward-amount left">
                        <p class="tip">
                            预估佣金
                            <reward-tooltip>
                                <p>所选时间段内已支付的推广订单的的预估佣金收入，</p>
                                <p>因买家退款等原因，可能与实际收入不一致。</p>
                            </reward-tooltip>
                        </p>
                        <p class="money">&yen;{{ overViewData.commission | formatPrice }}</p>
                    </div>
                    <div class="reward-amount un right">
                        <p class="tip">
                            订单量
                            <reward-tooltip>
                                <p>由推广带来的您选择时间段内的用户付款的订单数量，本数据为预估数据，</p>
                                <p>因买家退款等原因，可能与实际订单数量不一致。</p>
                            </reward-tooltip>
                        </p>
                        <p class="money">{{ overViewData.orderCount | formatPrice }}</p>
                    </div>
                </div>
                <order-tab
                    :position-options="positionOptions"
                    :position-selected="positionSelected"
                    :chart-data="chartData"
                    :page-sizes="pageSizes"
                    :page-size="pageSize"
                    :page-index="pageIndex"
                    :table-data="tableData"
                    :chart-width="chartWidth"
                    :chart-height="chartHeight"
                    @supr="supr"
                />
            </el-tab-pane>
            <el-tab-pane>
                <div slot="label">
                    <div class="reward-amount un">
                        <p class="tip">
                            预估活动服务费
                            <reward-tooltip>
                                <p>参与活动所获得的除商品佣金以外的额外奖励（不包括新客奖励与赚客邀请</p>
                                <p>新赚客奖励，个人赚客可前往微信端“考拉赚客”公众号查进行查看）。本数</p>
                                <p>据为预估值，仅供参考，最终收入金额以月结后您账户内实际收到的为准。</p>
                            </reward-tooltip>
                        </p>
                        <p class="money">&yen;{{ overViewData.rewardAmount | formatPrice }}</p>
                    </div>
                </div>
                <reward-tab
                    :on-going-activity-count="overViewData.onGoingActivityCount"
                    :chart-data="rewardChartData"
                    :page-sizes="pageSizes"
                    :page-size="rewardPageSize"
                    :page-index="rewardPageIndex"
                    :table-data="rewardTableData"
                    :chart-width="chartWidth"
                    :chart-height="chartHeight"
                    @supr="supr"
                />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import Tooltip from '../settle/tooltip';
import OrderTab from './orderTab';
import RewardTab from './rewardTab';

export default {
    components: {
        'reward-tooltip': Tooltip,
        'order-tab': OrderTab,
        'reward-tab': RewardTab
    },
    props: {
        overViewData: Object,
        positionOptions: Array,
        positionSelected: String,
        chartData: Object,
        pageSizes: Array,
        pageSize: Number,
        pageIndex: Number,
        tableData: Object,
        rewardChartData: Object,
        rewardTableData: Object,
        rewardPageIndex: Number,
        rewardPageSize: Number
    },
    data() {
        return {
            chartWidth: 0,
            chartHeight: 0
        };
    },
    mounted() {
        this.initChartSize();
    },
    methods: {
        initChartSize() {
            this.$nextTick(() => {
                const container = this.$refs.allTabs;
                this.chartWidth = parseInt(container.clientWidth, 10);
                this.chartHeight = 400;
            });
        },
        tabChange(tab) {
            this.supr('assign', {
                tabSelected: tab.index
            });
        },
        supr(...args) {
            this.$emit('supr', ...args);
        }
    }
};
</script>

<style lang="scss" scoped>
.all-tabs {
    .reward-amount {
        display: inline-block;
        line-height: 1.4;
        font-size: 0;
        &.un {
            border-right: 0;
        }
        &.left {
            padding-right: 20px;
            border-right: 1px solid #e3e3e3;
        }
        &.right {
            padding-left: 20px;
        }
        .tip {
            font-size: 14px;
        }
        .money {
            margin-top: 10px;
            font-size: 24px;
            // font-weight: bold;
            color: red;
        }
        .separator {
            font-size: 16px;
            font-weight: bold;
            color: #303133;
            &.down {
                margin-top: 10px;
            }
        }
    }
}
</style>

<style lang="scss">
.all-tabs {
    .el-tabs--card > .el-tabs__header .el-tabs__nav {
        .el-tabs__item {
            height: 100%;
            line-height: 0;
            padding-top: 20px;
            padding-bottom: 20px;
            &.is-active {
                color: #303133;
                background-color: #f7f7f7;
                border-bottom-color: #f7f7f7;
            }
            &:hover {
                color: #303133;
            }
        }
    }
}
</style>

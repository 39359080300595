<template>
    <div class="order">
        <div class="zk-main-title">
            <h2>订单明细</h2>
        </div>

        <el-card class="query-form">
            <el-form :inline="true" :model="queryForm" ref="queryForm">
                <el-form-item label="订单来源" prop="bizType">
                    <el-select v-model="queryForm.bizType" filterable>
                        <el-option
                            label="全部（猫享与考拉）"
                            :value="-1"
                        />
                        <el-option
                            label="猫享自营"
                            :value="1"
                        />
                        <el-option
                            label="考拉海购"
                            :value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="下单时间" prop="queryTime">
                    <el-date-picker
                        v-model="queryForm.queryTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="['00:00:00', '00:00:00']"
                        value-format="timestamp"
                        :picker-options="pickerOptions"
                    />
                </el-form-item>
                <el-form-item label="结算状态" prop="status">
                    <el-select v-model="queryForm.status">
                        <el-option
                            v-for="item in settleStatusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="推广位" prop="positionId">
                    <el-select v-model="queryForm.positionId" filterable>
                        <el-option
                            v-for="item in positionOptions"
                            :key="item.positionId"
                            :label="item.name"
                            :value="item.positionId"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="订单号" prop="orderId">
                    <el-input v-model="queryForm.orderId" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onQuery">查询</el-button>
                    <el-button @click="resetForm">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="query-table" v-loading="loading">
            <div class="result-table" v-if="tableData">
                <div class="result-table-header clearfix">
                    <span class="result-table-title">推广订单</span>
                    <div class="result-table-export">
                        <el-button type="primary" @click="exportEmail">导出</el-button>
                    </div>
                </div>
                <div class="result-table-content clearfix">
                    <el-table :data="tableData.data">
                        <el-table-column prop="orderId" label="订单号" min-width="2" />
                        <el-table-column
                            prop="bizType"
                            :formatter="formatBizType"
                            label="订单来源"
                            min-width="2"
                        />
                        <el-table-column
                            prop="orderTime"
                            :formatter="formatOrderTime"
                            label="下单时间"
                            min-width="3"
                        />
                        <el-table-column prop="positionName" label="推广位" min-width="2" />
                        <el-table-column prop="payAmount" :formatter="formatAmount" min-width="2">
                            <template slot="header">
                                <div style="padding-left: 0;">
                                    <span>支付金额 </span>
                                    <reward-tooltip>
                                        <p>买家为购买该笔订单下的全部商品，实际支付的金额。</p>
                                    </reward-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="actualPayAmount"
                            :formatter="formatAmount"
                            min-width="2"
                        >
                            <template slot="header">
                                <div style="padding-left: 0;">
                                    <span>订单净额 </span>
                                    <reward-tooltip>
                                        <p>
                                            买家实付金额中扣除邮费、税费、退款以及礼品卡支付的费用。
                                        </p>
                                    </reward-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="commissionAmount"
                            :formatter="formatAmount"
                            min-width="2"
                        >
                            <template slot="header">
                                <div style="padding-left: 0;">
                                    <span>预估佣金 </span>
                                    <reward-tooltip>
                                        <p>
                                            所选时间段内已支付的推广订单的的预估佣金收入，
                                        </p>
                                        <p>因买家退款等原因，可能与实际收入不一致。</p>
                                    </reward-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column min-width="2">
                            <template slot="header">
                                <div style="padding-left: 0;">
                                    <span>结算状态 </span>
                                    <reward-tooltip>
                                        <p>
                                            待结算：订单交易完成，且处于该订单对应的结算期。
                                        </p>
                                        <p>
                                            已结算：订单结算完成，每月10日，对上个自然月确认收货订单进行结算。
                                        </p>
                                        <p>
                                            已失效：订单已取消或订单内商品全部发生售后，无需结算的订单。
                                        </p>
                                    </reward-tooltip>
                                </div>
                            </template>

                            <template slot-scope="scope">
                                <el-tag :type="formatSettleStatusType(scope.row.settleStatus)">
                                    {{ formatSettleStatus(scope.row.settleStatus) }}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" type="expand" min-width="2">
                            <template slot-scope="scope">
                                <div
                                    class="reward-expand-content"
                                    v-if="
                                        scope.row.zhuankeOrderItemList &&
                                            scope.row.zhuankeOrderItemList.length
                                    "
                                >
                                    <el-table
                                        :data="scope.row.zhuankeOrderItemList"
                                        style="width: 100%"
                                        :row-style="{ background: '#f7f7f7' }"
                                        :header-cell-style="{ background: '#f7f7f7' }"
                                    >
                                        <el-table-column prop="goodsName" label="商品名称" />
                                        <el-table-column prop="goodsId" label="商品id" />
                                        <el-table-column prop="categoryName" label="类目" />
                                        <el-table-column
                                            prop="buyCount"
                                            :formatter="formatPrice"
                                            label="购买数量"
                                        />
                                        <el-table-column
                                            prop="payAmount"
                                            :formatter="formatAmount"
                                            label="实际成交价"
                                        />
                                        <el-table-column
                                            prop="commissionRate"
                                            :formatter="formatRate"
                                            label="佣金比例"
                                        />
                                        <el-table-column
                                            prop="commissionAmount"
                                            :formatter="formatAmount"
                                            label="预估佣金"
                                        />
                                    </el-table>
                                </div>
                                <div
                                    v-else
                                    style="text-align: center; background: #f5f5f5;line-height: 48px;"
                                >
                                    暂无数据
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="pagination" v-if="tableData.data && tableData.data.length > 0">
                        <pagination
                            :total="tableData.totalCount"
                            :page-sizes="pageSizes"
                            :page-size="queryForm.pageSize"
                            :current-page="queryForm.pageIndex"
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                        />
                    </div>
                </div>
            </div>
        </el-card>

        <export-email-dialog :visible.sync="emailDialogVisible" @email-value="getExportEmail" />
    </div>
</template>

<script>
import moment from 'moment';
import ExportEmailDialog from '../../components/settle/export-email';
import pagination from '../../components/common/pagination.vue';
import formatPrice from '../../utils/format-price';
import formatBizType from '../../utils/format-biz-type';
import Tooltip from '../../components/settle/tooltip';

export default {
    components: {
        pagination,
        'reward-tooltip': Tooltip,
        'export-email-dialog': ExportEmailDialog
    },
    data() {
        const todayStartTime = moment(moment().format('YYYY-MM-DD')).valueOf();
        const dayMs = 24 * 60 * 60 * 1000;
        const settleStatusOptions = [
            {
                label: '全部',
                value: -1
            },
            {
                label: '待结算',
                value: 0
            },
            {
                label: '已结算',
                value: 1
            },
            {
                label: '已失效',
                value: 2
            }
        ];
        const positionOptions = [
            {
                positionId: '-1',
                name: '全部'
            }
        ];

        const pageSizes = [20, 50, 100, 200, 500];
        const timeLimit = {
            min: null,
            max: todayStartTime
        };
        return {
            settleStatusOptions,
            positionOptions,
            pickerOptions: {
                disabledDate: (date) => {
                    const curDate = date.getTime();
                    const { min: minDate, max: maxDate } = timeLimit;
                    let optional = true;
                    if (minDate) {
                        optional = optional && curDate >= minDate;
                    }
                    if (maxDate) {
                        optional = optional && curDate <= maxDate;
                    }
                    return !optional;
                },

                onPick: ({ minDate, maxDate }) => {
                    if (minDate && !maxDate) {
                        timeLimit.min = moment(minDate)
                            .subtract(1, 'months')
                            .add(1, 'days')
                            .valueOf();

                        const timeLimitMax = moment(minDate)
                            .add(1, 'months')
                            .subtract(1, 'days')
                            .valueOf();
                        timeLimit.max =
                            timeLimitMax > todayStartTime ? todayStartTime : timeLimitMax;
                    } else {
                        timeLimit.min = null;
                        timeLimit.max = todayStartTime;
                    }
                }
            },
            queryForm: {
                queryTime: [todayStartTime - dayMs * 6, todayStartTime],
                status: settleStatusOptions[0].value,
                positionId: positionOptions[0].positionId,
                orderId: '',
                pageIndex: 1,
                bizType: -1,
                pageSize: pageSizes[0]
            },
            pageSizes,
            tableData: {
                totalCount: 0,
                data: []
            },
            emailDialogVisible: false,
            loading: false
        };
    },
    mounted() {
        this.getCorpZhuankePromotionPosition();
    },
    methods: {
        async getCorpZhuankePromotionPosition() {
            const data = await this.$request({
                url: '/api/promotion/getCorpZhuankePromotionPosition'
            });

            this.positionOptions = [...this.positionOptions, ...(data || [])];

            this.initQueryData();
            this.onQuery();
        },
        initQueryData() {
            let { startTime, endTime, positionId } = this.$route.query;
            startTime = parseInt(startTime, 10);
            endTime = parseInt(endTime, 10);
            if (startTime && endTime) {
                this.queryForm.queryTime = [
                    moment(moment(startTime).format('YYYY-MM-DD')).valueOf(),
                    moment(moment(endTime).format('YYYY-MM-DD')).valueOf()
                ];
            }
            if (positionId) {
                this.queryForm.positionId = positionId;
            }
        },
        onQuery() {
            this.resetTable();
            this.queryTable();
        },
        resetTable() {
            this.queryForm.pageIndex = 1;
            this.queryForm.pageSize = this.pageSizes[0];
        },
        async queryTable() {
            let {
                queryTime: [startTime, endTime],
                status,
                positionId,
                orderId,
                pageIndex,
                pageSize,
                bizType
            } = this.queryForm;
            endTime += 24 * 60 * 60 * 1000;

            this.loading = true;
            const params = {
                startTime,
                endTime,
                status,
                positionId,
                orderId,
                pageIndex,
                pageSize
            };

            if(bizType !== -1) {
                params.bizType = bizType;
            }

            const data = await this.$request({
                url: '/api/report/order/getOrderDetailReportByParam',
                method: 'post',
                data: params
            });

            this.loading = false;
            const { maxCount, zhuankeReportOrderDetailList } = data || {};

            this.tableData = { totalCount: maxCount, data: zhuankeReportOrderDetailList };
        },
        resetForm() {
            this.$refs.queryForm.resetFields();
        },
        exportEmail() {
            this.emailDialogVisible = true;
        },
        getExportEmail(email) {
            let {
                queryTime: [startTime, endTime],
                status,
                positionId,
                orderId
            } = this.queryForm;
            endTime += 24 * 60 * 60 * 1000;

            this.$request({
                url: '/api/report/order/exportOrderDetail',
                method: 'post',
                origin: true,
                data: {
                    startTime,
                    endTime,
                    positionId,
                    status,
                    orderId,
                    email
                }
            })
                .then((data) => {
                    if (data.code === 200) {
                        this.showExportOftenAlert();
                    } else {
                        this.showExportErrorAlert(data.desc || '');
                    }
                })
                .catch(() => {
                    this.showExportErrorAlert();
                });
        },
        showExportErrorAlert(errText) {
            const err = errText ? errText : '导出失败';
            this.$alert(err, '提示', {
                confirmButtonText: '确定'
            });
        },

        showExportOftenAlert() {
            this.$alert(
                '您下载的文件正在生成，请不要重复点击，文件生成后，将会以邮件形式发送给您',
                '提示',
                {
                    confirmButtonText: '确定'
                }
            );
        },
        handleCurrentChange(newPageIndex) {
            this.queryForm.pageIndex = newPageIndex;
            this.queryTable();
        },
        handleSizeChange(newPageSize) {
            this.queryForm.pageIndex = 1;
            this.queryForm.pageSize = newPageSize;
            this.queryTable();
        },
        formatOrderTime(row, column, cellValue) {
            return moment(parseInt(cellValue, 10) || Date.now()).format('YYYY.MM.DD HH:mm:ss');
        },
        formatPrice(row, column, cellValue) {
            return formatPrice(cellValue);
        },
        formatBizType(row) {
            return formatBizType(row.bizType);
        },
        formatAmount(row, column, cellValue) {
            return `¥${formatPrice(cellValue)}`;
        },
        formatRate(row, column, cellValue) {
            return `${parseFloat(cellValue) * 100}%`;
        },
        formatSettleStatus(v) {
            return (
                {
                    0: '待结算',
                    1: '已结算',
                    2: '已失效'
                }[parseInt(v, 10)] || ''
            );
        },
        formatSettleStatusType(v) {
            return {
                0: 'danger',
                1: 'success',
                2: 'info'
            }[parseInt(v, 10)];
        }
    }
};
</script>

<style lang="scss" scoped>
.order {
    .query-form {
    }
    .query-table {
        margin-top: 20px;
        .result-table {
            .result-table-header {
                .result-table-title {
                    font-size: 18px;
                    line-height: 40px;
                    font-weight: bold;
                }
                .result-table-export {
                    float: right;
                }
            }
            .result-table-content {
                .pagination {
                    margin-top: 10px;
                    text-align: center;
                }
            }
        }
        .clearfix:before,
        .clearfix:after {
            display: table;
            content: '';
        }
        .clearfix:after {
            clear: both;
        }
    }
}
</style>

<style lang="scss">
.order {
    .query-form {
        .el-range-separator {
            box-sizing: content-box;
        }
        .el-form-item {
            margin-right: 20px;
        }
        .el-card__body {
            padding-bottom: 0;
        }
    }
}
</style>

<template>
    <div class="all">
        <el-card class="all-card">
            <div slot="header" class="clearfix">
                <span class="all-card-title">整体报表</span>
                <el-select v-model="bizType" filterable @change="query()" style="margin-left: 450px;">
                    <el-option
                        label="全部（猫享与考拉）"
                        :value="-1"
                    />
                    <el-option
                        label="猫享自营"
                        :value="1"
                    />
                    <el-option
                        label="考拉海购"
                        :value="0"
                    />
                </el-select>
                <div class="all-query-time">
                    <el-date-picker
                        v-model="queryTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="['00:00:00', '00:00:00']"
                        value-format="timestamp"
                        :picker-options="pickerOptions"
                        @change="query"
                    />
                </div>
            </div>
            <all-tabs
                :over-view-data="overViewData"
                :position-options="positionOptions"
                :position-selected="positionSelected"
                :chart-data="chartData"
                :page-sizes="pageSizes"
                :page-size="pageSize"
                :page-index="pageIndex"
                :table-data="tableData"
                :reward-chart-data="rewardChartData"
                :reward-table-data="rewardTableData"
                :reward-page-index="rewardPageIndex"
                :reward-page-size="rewardPageSize"
                v-loading="loading"
                element-loading-background="transparent"
                @supr="supr"
            />
        </el-card>

        <export-email-dialog :visible.sync="emailDialogVisible" @email-value="getExportEmail" />
    </div>
</template>

<script>
import moment from 'moment';
import AllTabs from '../../components/reportAll/tabs';
import ExportEmailDialog from '../../components/settle/export-email';

export default {
    components: {
        'all-tabs': AllTabs,
        'export-email-dialog': ExportEmailDialog
    },
    data() {
        const todayStartTime = moment(moment().format('YYYY-MM-DD')).valueOf();
        const queryStartTime = moment(
            moment()
                .subtract(3, 'months')
                .format('YYYY-MM-DD')
        ).valueOf();
        const dayMs = 24 * 60 * 60 * 1000;
        const positionOptions = [
            {
                positionId: '-1',
                name: '全部'
            }
        ];

        const pageSizes = [20, 40, 60, 80];

        return {
            bizType: -1,
            emailDialogVisible: false,
            pickerOptions: {
                disabledDate: (date) =>
                    date.getTime() >= todayStartTime || date.getTime() < queryStartTime
            },
            tabSelected: '0',
            queryTime: [todayStartTime - dayMs * 7, todayStartTime - dayMs * 1], // 查询时间
            positionOptions, // 推广位信息
            positionSelected: positionOptions[0].positionId, // 选中推广位
            overViewData: {
                commission: 0, // 预估佣金
                orderCount: 0, // 订单量
                rewardAmount: 0 // 预估活动服务费
            },
            chartData: null, // 图表数据
            pageSizes,
            pageIndex: 1,
            pageSize: 40,
            tableData: { totalCount: 0, data: [] }, // 表格数据
            rewardChartData: null, //奖励金额图表
            rewardTableData: { totalCount: 0, data: [] }, // 奖励表格数据
            rewardPageIndex: 1,
            rewardPageSize: 40,
            loading: false
        };
    },
    methods: {
        resetTable() {
            this.pageIndex = 1;
            this.pageSize = 40;
            this.rewardPageIndex = 1;
            this.rewardPageSize = 40;
        },
        /*eslint-disable*/
        async query() {
            this.loading = true;
            this.resetTable();

            let {
                queryTime: [startTime, endTime],
                positionSelected: positionId,
                pageIndex,
                pageSize,
                rewardPageIndex,
                rewardPageSize,
                bizType
            } = this;
            //endTime += 24 * 60 * 60 * 1000;

            const params = {
                positionId,
                startTime,
                endTime,
                pageIndex,
                pageSize,
                rewardPageIndex,
                rewardPageSize,
            }

            if(bizType !== -1) {
                params.bizType = bizType
            }

            const data = await this.$request({
                url: '/api/report/all/order',
                method: 'post',
                data: params
            });
            /*eslint-disable*/
            this.loading = false;
            const { overViewData, chartData, tableData, rewardChartData, rewardTableData } =
                data || {};
            this.overViewData = overViewData;
            this.chartData = chartData;
            this.tableData = tableData;
            this.rewardChartData = rewardChartData;
            this.rewardTableData = rewardTableData;
        },
        async queryTable() {
            this.loading = true;
            let {
                queryTime: [startTime, endTime],
                positionSelected: positionId,
                pageIndex,
                pageSize,
                bizType
            } = this;
            endTime += 24 * 60 * 60 * 1000;

            const params = {
                positionId,
                startTime,
                endTime,
                pageIndex,
                pageSize
            }

            if(bizType !== -1) {
                params.bizType = bizType
            }

            const data = await this.$request({
                url: '/api/report/all/orderTable',
                method: 'post',
                data: params
            });

            this.loading = false;
            this.tableData = data || {};
        },
        async queryRewardTable() {
            this.loading = true;
            let {
                queryTime: [startTime, endTime],
                rewardPageIndex,
                rewardPageSize,
                bizType
            } = this;
            endTime += 24 * 60 * 60 * 1000;

            const params = {
                startTime,
                endTime,
                pageIndex: rewardPageIndex,
                pageSize: rewardPageSize,
            }

            if(bizType !== -1) {
                params.bizType = bizType
            }

            const data = await this.$request({
                url: '/api/report/all/rewardTable',
                method: 'post',
                data: params
            });

            this.loading = false;
            this.rewardTableData = data || {};
        },
        async getCorpZhuankePromotionPosition() {
            const data = await this.$request({
                url: '/api/promotion/getCorpZhuankePromotionPosition'
            });

            this.positionOptions = [...this.positionOptions, ...(data || [])];
        },
        supr(method = 'assign', ...args) {
            if (method === 'assign') {
                Object.assign(this, ...args);
            } else {
                this[method](...args);
            }
        },
        exportEmail() {
            this.emailDialogVisible = true;
        },
        getExportEmail(email) {
            let {
                queryTime: [startTime, endTime],
                positionSelected: positionId
            } = this;
            endTime += 24 * 60 * 60 * 1000;

            const url = {
                0: '/api/report/all/exportOrderOverview',
                1: '/api/report/all/exportActivityOverview'
            }[parseInt(this.tabSelected, 10) || 0];

            this.$request({
                url,
                method: 'post',
                origin: true,
                data: {
                    startTime,
                    endTime,
                    positionId,
                    email
                }
            })
                .then((data) => {
                    if (data.code === 200) {
                        this.showExportOftenAlert();
                    } else {
                        this.showExportErrorAlert(data.desc || '');
                    }
                })
                .catch(() => {
                    this.showExportErrorAlert();
                });
        },

        showExportErrorAlert(errText) {
            const err = errText ? errText : '导出失败';
            this.$alert(err, '提示', {
                confirmButtonText: '确定'
            });
        },

        showExportOftenAlert() {
            this.$alert(
                '您下载的文件正在生成，请不要重复点击，文件生成后，将会以邮件形式发送给您',
                '提示',
                {
                    confirmButtonText: '确定'
                }
            );
        }
    },
    mounted() {
        this.query();
        this.getCorpZhuankePromotionPosition();
    }
};
</script>

<style lang="scss" scoped>
.all {
    .all-card {
        .all-card-title {
            font-size: 18px;
            line-height: 40px;
            font-weight: bold;
        }
        .all-query-time {
            float: right;
        }
        .clearfix:before,
        .clearfix:after {
            display: table;
            content: '';
        }
        .clearfix:after {
            clear: both;
        }
    }
}
</style>

<style lang="scss">
.all-query-time {
    .el-range-separator {
        box-sizing: content-box;
    }
}

.all {
    .all-card {
        .el-card__header {
            border-bottom: none;
        }

        .el-card__body {
            padding-top: 0;
        }
    }
}
</style>

<template>
    <div class="order-tab">
        <div class="order-tab-header clearfix">
            <div class="query-zone">
                <span class="position-label">推广位</span>
                <el-select v-model="positionId" filterable @change="positionChange">
                    <el-option
                        v-for="item in positionOptions"
                        :key="item.positionId"
                        :label="item.name"
                        :value="item.positionId"
                    />
                </el-select>
            </div>
        </div>

        <div class="result-chart" v-show="!hideChart">
            <div ref="orderChart" class="order-chart" />
        </div>

        <div class="result-table" v-if="tableData">
            <div class="result-table-header clearfix">
                <span class="result-table-title">推广订单</span>
                <div class="result-table-export">
                    <el-button type="danger" @click="exportEmail">导出</el-button>
                </div>
            </div>
            <div class="result-table-content clearfix">
                <el-table :data="tableData.data">
                    <el-table-column
                        prop="statDate"
                        :formatter="formatDate"
                        label="日期"
                        min-width="2"
                    />
                    <el-table-column
                        prop="bizType"
                        :formatter="formatBizType"
                        label="订单来源"
                        min-width="2"
                    />
                    <el-table-column prop="positionName" label="推广位" min-width="3" />
                    <el-table-column
                        prop="orderCount"
                        label="订单量"
                        :formatter="formatPrice"
                        min-width="2"
                    />
                    <el-table-column
                        prop="payAmount"
                        label="支付金额"
                        :formatter="formatAmount"
                        min-width="2"
                    />
                    <el-table-column prop="actualPayAmount" :formatter="formatAmount" min-width="2">
                        <template slot="header">
                            <div style="padding-left: 0;">
                                <span>订单净额 </span>
                                <reward-tooltip>
                                    <p>买家实付金额中扣除邮费、税费、退款以及礼品卡支付的费用。</p>
                                </reward-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="commission"
                        :formatter="formatAmount"
                        min-width="2"
                    >
                        <template slot="header">
                            <div style="padding-left: 0">
                                <span>预估佣金 </span>
                                <reward-tooltip>
                                    <p>所选时间段内已支付的推广订单的的预估佣金收入，</p>
                                    <p>因买家退款等原因，可能与实际收入不一致。</p>
                                </reward-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="2">
                        <template slot-scope="scope">
                            <el-button @click="goOrderDetail(scope.row)" type="text" size="small" style="color: #333">
                                查看
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="pagination" v-if="tableData.data && tableData.data.length > 0">
                    <pagination
                        :total="tableData.totalCount"
                        :page-sizes="pageSizes"
                        :page-size="pageSize"
                        :current-page="pageIndex"
                        @current-change="handleCurrentChange"
                        @size-change="handleSizeChange"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import pagination from '../common/pagination.vue';
import formatPrice from '../../utils/format-price';
import formatBizType from '../../utils/format-biz-type';
import Tooltip from '../../components/settle/tooltip';
// echarts模块较大 按需引入
const echarts = require('echarts/lib/echarts');
require('echarts/lib/chart/line');
require('echarts/lib/component/tooltip');
require('echarts/lib/component/legend');

export default {
    components: {
        pagination,
        'reward-tooltip': Tooltip
    },
    props: {
        positionOptions: Array,
        positionSelected: String,
        chartData: Object,
        pageSizes: Array,
        pageSize: Number,
        pageIndex: Number,
        tableData: Object,
        chartWidth: Number,
        chartHeight: Number
    },
    data() {
        return {
            positionId: this.positionSelected,
            myChart: null,
            hideChart: false
        };
    },
    watch: {
        chartData() {
            const { chartData } = this;
            if (chartData && chartData.date && chartData.date.length) {
                this.renderChart();
            } else {
                this.hideChart = true;
            }
        },
        chartWidth() {
            if (this.chartWidth && this.chartHeight) {
                this.initChart();
            }
        }
    },
    methods: {
        supr(...args) {
            this.$emit('supr', ...args);
        },
        positionChange() {
            this.supr('assign', { positionSelected: this.positionId });
            this.supr('query');
        },
        initChart() {
            if (this.myChart) {
                return;
            }
            this.myChart = echarts.init(this.$refs.orderChart, '', {
                width: this.chartWidth,
                height: this.chartHeight
            });
        },
        renderChart() {
            if (!this.myChart) {
                return;
            }
            const { date, order, commission } = this.chartData;
            const formatDate = date.map((d) => moment(d).format('YYYY-MM-DD'));
            // 绘制图表
            this.myChart.setOption({
                grid: {
                    left: 60,
                    right: 60
                },
                legend: {
                    data: ['订单量', '预估佣金'],
                    x: 'center',
                    y: 'bottom'
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: (params) => {
                        let tip = '';
                        (params || []).forEach((d, i) => {
                            if (i === 0) {
                                tip += `${d.axisValue}<br />`;
                            }
                            tip += d.marker;
                            let value =
                                d.seriesName === '预估佣金'
                                    ? `¥${formatPrice(d.value)}`
                                    : formatPrice(d.value);
                            tip += `${d.seriesName}：${value}<br />`;
                        });
                        return tip;
                    }
                },
                xAxis: {
                    data: formatDate,
                    boundaryGap: true,
                    axisTick: {
                        alignWithLabel: true
                    }
                },
                yAxis: [
                    {
                        position: 'left',
                        name: '预估佣金（元）',
                        max(value) {
                            return Math.ceil(value.max + (value.max-value.min)/5);
                        },
                        min(value) {
                            return Math.floor(value.min - (value.max-value.min)/5) < 0 ? 0 : Math.floor(value.min - (value.max-value.min)/5);
                        }
                    },
                    {
                        position: 'right',
                        name: '订单量(个)',
                        splitLine: {
                            show: false
                        },
                        max(value) {
                            return Math.ceil(value.max + (value.max-value.min)/5);
                        },
                        min(value) {
                            return Math.floor(value.min - (value.max-value.min)/5) < 0 ? 0 : Math.floor(value.min - (value.max-value.min)/5);
                        }
                    }
                ],
                series: [
                    {
                        name: '订单量',
                        type: 'line',
                        data: order,
                        yAxisIndex: 1,
                        itemStyle: { color: '#4FABFF' }
                    },
                    {
                        name: '预估佣金',
                        type: 'line',
                        data: commission,
                        yAxisIndex: 0,
                        itemStyle: { color: '#FF5E5E' }
                    }
                ]
            });
        },
        exportEmail() {
            this.supr('exportEmail');
        },
        goOrderDetail(row) {
            let { positionId, statDate } = row;
            this.$router.push({
                path: '/report/order',
                query: {
                    startTime: statDate,
                    endTime: statDate,
                    positionId: positionId === 'other' ? '-1' : positionId
                }
            });
        },
        handleSizeChange(newSize) {
            this.supr('assign', {
                pageIndex: 1,
                pageSize: newSize
            });
            this.supr('queryTable');
        },
        handleCurrentChange(newIndex) {
            this.supr('assign', {
                pageIndex: newIndex
            });
            this.supr('queryTable');
        },
        formatDate(row, column, cellValue) {
            return moment(parseInt(cellValue, 10)).format('YYYY.MM.DD');
        },
        formatAmount(row, column, cellValue) {
            return `¥${formatPrice(cellValue)}`;
        },
        formatPrice(row, column, cellValue) {
            return formatPrice(cellValue);
        },
        formatBizType(row) {
            return formatBizType(row.bizType);
        }
    }
};
</script>

<style lang="scss" scoped>
.order-tab {
    .order-tab-header {
        .query-zone {
            float: right;
            .position-label {
                margin-right: 20px;
            }
        }
    }

    .result-table {
        margin-top: 20px;
        .result-table-header {
            .result-table-title {
                font-size: 18px;
                line-height: 40px;
                font-weight: bold;
            }
            .result-table-export {
                float: right;
            }
        }
        .result-table-content {
            .pagination {
                margin-top: 10px;
                text-align: center;
            }
        }
    }
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: '';
    }
    .clearfix:after {
        clear: both;
    }
}
</style>
